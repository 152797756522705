import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"


class Body extends Component {



    render() {

        // const ourteam = {
        //     backgroundImage: 'url( images/ourteam.jpg)',
        //     backgroundSize: '100% 100% '
        // };
        // const missionvisionvalues = {
        //     backgroundImage: 'url( images/missionvisionvalues.png)',
        //     backgroundSize: '100% 100%'

        // };
        // const ourstory = {
        //     backgroundImage: 'url( images/ourstory.jpg)',
        //     backgroundSize: '100% 100%'

        // };
        // const nounderline = {
        //     textDecoration: 'none',

        // };
        // const textblack = {
        //     Color: 'black'
        // };

        return (

            <div>

                {/* <!-- Icons Grid --> */}
                <section className="features-icons bg-light text-center">
                    <div className="container ">
                        <h2 className="text-left">Welcome to  B.S.P Proficiency Private Limited </h2>


                        <p className="font-weight-light text-justify mb-5">
                            BSP is a brand that is in the business of positive transformation of individuals and organizations.
                            The key to our working style is partnership. We start by understanding the challenges that your
                             business is facing to provide the precise consultancy support you require, and work with you to
                             propose solutions that answer your needs. We work all over the Nepal in many market sectors,
                             offering a comprehensive range of services including Financial management, Tax Planning, Human
                             Resource Management, operations, performance efficiency, strategy making and technology consulting.
                             In addition, we have been equipped with a team having high level of professionalism blended with
                                     expertise in the relevant field.</p>
                        {/* <p className="font-weight-light text-justify mb-5">
                            BSP Proficiency Private Limited, Business Advisory (hereinafter referred as the firm) is a
                            firm of prominent Professionals including Chartered Accountants established in 2019. It
                            specializes in Auditing and Financial as well as management consultancy works, which include
                            Annual Auditing, Internal Auditing, Compliance Audit, Due Diligence Audit, Quality assurance audit,
                            Tax Audit, Financial Analysis, Financial Review, Direct and Indirect Tax Consultancy, Joint Venture
                            Laws, Investigation, Preparation and Implementation of Financial Manual and Accounting Manual,
                            Capital market and Public issues, Financial Consultancy (Capital restructuring, loan Syndication,
                            Merger and Acquisition, strategic alliances, strategic sale of entity etc.), Project Management
                            and case study. In addition, we have been equipped with a team having high level of professionalism
                                    blended with expertise in the relevant field.</p> */}

                        <div className="row">
                            <div className="container  ">
                                <div className="card-deck ml-3 mb-3 mr-3">
                                    <div className="card grad2">
                                        <div className="card-body">
                                            <h3 className="card-title fontcolor font-weight-bold text-center ">WHO</h3>
                                             <p className="card-text font-weight-normal  "> BSP is a brand that is in the business of positive transformation of individuals and organizations.
                                        The key to our working style is partnership. We start by understanding the challenges that your
                                         business is facing to provide the precise consultancy support you require, and work with you to
                                         propose solutions that answer your needs. We work all over the Nepal in many market sectors,
                                         offering a comprehensive range of services including Financial management, Tax Planning, Human
                                         Resource Management, operations, performance efficiency, strategy making and technology consulting.
                                         In addition, we have been equipped with a team having high level of professionalism blended with
                                     expertise in the relevant field.</p>
                                        </div>
                                        <Link to='ourteam' className= 'mb-3'> <button type="submit" className="btn btn-primary text-right">Team</button></Link>
                                    </div>
                                    <div className="card grad2">
                                        <div className="card-body">
                                            <h3 className="card-title fontcolor font-weight-bold text-center"> WHAT</h3>
                                              <p className="card-text font-weight-normal heightmaintain">Our values define who we are, for us this means:</p>
                                            <ul>
                                           <li>The horizon of our consulting services is inclined in the Short, Medium and Long term.</li><li> The interest of our client is allways in upmost priority.</li>
                                           <li>The Important to maintain the Secrecy of client information has always our prime concern.</li> <li>The services we deliver
                                        are the best of our knowledge and experience we hold to every client as cost effectievely</li>
                                        </ul></div>
                                        <Link to='ourservices' className= 'mb-3'> <button type="submit" className="btn btn-primary text-right">Services</button></Link>
                                    </div>
                                    <div className="card grad2">
                                        <div className="card-body">
                                            <h3 className="card-title fontcolor font-weight-bold text-center "> WHERE</h3>
                                              <p className="card-text font-weight-normal heightmaintain">BSP Consultancy offers services to a
                                            national client base from office in Aloknagar, Kathmandu. Our extensive experience in both emerging and developed markets gives
                                BSP a broad perspective and a well-rounded understanding of the industry from a national, regional, and local perspective.</p>
                                        </div>
                                        <Link to='contactus' className= 'mb-3'> <button type="submit" className="btn btn-primary text-right  ">Contact Us</button></Link>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-lg-4">
                                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                                    <Link to='/ourteam' style={nounderline}>
                                        <div className=" features-icons-icon d-flex " style={ourteam}>
                                        </div>
                                        <h3 className="text-dark">Our Team</h3>
                                        <p className="lead mb-0" className="text-dark">View our hardworkig and dedicated team.</p>
                                    </Link>
                                </div>
                            </div>

                            
                            <div className="col-lg-4">
                                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3" >
                                    <Link to='/ourteam' style={nounderline}>
                                        <div className=" features-icons-icon d-flex" style={missionvisionvalues}>
                                        </div>
                                        <h3 className="text-dark">Mission and Vision</h3>
                                        <p className="lead mb-0" className="text-dark"> Our mission and vision</p>
                                    </Link>
                                </div>
                            </div>

                             

                            <div className="col-lg-4">
                                <div className="features-icons-item mx-auto mb-0 mb-lg-3" >
                                    <Link to='/ourteam' style={nounderline}>

                                        <div className=" features-icons-icon d-flex" style={ourstory}>
                                        </div>
                                        <h3 className="text-dark">Our Story</h3>
                                        <p className="lead mb-0" className="text-dark">See how the company is formed and our story of success </p>
                                    </Link>

                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </div >
        );
    }
}
export default Body;
