import React, { Component } from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"
import NavBar from "../NavBar"
import Footer from '../footer';
import Sidebar from '../servicessidebar';


class BusinessAdvisory extends Component {



    render() {



        // const audit = {
        //     backgroundImage: 'url( images/audit.png)',
        //     backgroundSize: '92%'

        // };


        return (

            <div className='bg-light'>

                {/* navbar div start */}
                <div>
                    <NavBar />
                </div>
                {/* navbar div end */}

                <div className="container">

                    <div class="row">

                        <div class="col-md-3 col-sm-3">

                            <Sidebar />
                        </div>
                        <div class="col-md-9 col-sm-9">
                            <section className="services-image bg-light text-left">
                                <div className="row">

                                    {/* legal */}
                                    <div className="mb-5 mb-lg-0 mb-lg-3">
                                        {/* <div className=" features-icons-icon d-flex" style={audit}>
                                        </div> */}
                                        <h3>Business Advisory</h3>
                                        <p className="lead mb-0">Our team develops and offers tailored, innovative and comprehensive solutions
                                         for specific client business needs. The main objective of offering business advisory services is to 
                                         bring more focus to the value proposition by delivering a broader range of advisory services and 
                                         competencies. BSP offers a full range of services to help clients tackle challenges faced in businesses.
                                          Currently, the set of five service solutions includes: </p>
                                          <ul className="lead mb-0">
                                              <li>Support and Guide business start-ups.</li>
                                              <li>Accounting advisory services.</li>
                                              <li>NFRA and NAS related Services.</li>
                                              <li>Business Management & Marketing.</li>
                                              <li>Finance function resourcing and outsourcing.</li>
                                          </ul>
                                    </div>

                                </div>
                            </section>
                        </div>
                    </div>
                </div>



                {/* footer div start*/}
                <div>
                    <Footer />
                </div>
                {/* footer div end*/}


            </div >

        );
    }
}
export default BusinessAdvisory;
