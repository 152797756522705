import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NavBar from "../NavBar"
import Footer from '../footer';







class ImpLinks extends Component {



    render() {

        return (

            <div className="bg-light">
                <div>
                    <NavBar />
                </div>

                {/* Body starts */}

                <div className='container   '>
                    <div className='row   '>
                        <div className='col-md-12'>
                            <h3 className='fontcolor font-weight-bold mt-4'>Important Links</h3>


                            {/* 1 */}
                            <hr />
                            <p className='mt-0 mb-0'>Inland Revenue Department</p>
                            <a href='https://www.ird.gov.np' target='blank'>www.ird.gov.np</a>

                            {/* 2 */}
                            <hr className='mt-2 mb-2' />
                            <p className='mt-0 mb-0'>Office of the Company Registrar</p>
                            <a href='http://www.ocr.gov.np' target='blank'>www.ocr.gov.np</a>


                            {/* 3 */}
                            <hr className='mt-2 mb-2' />
                            <p className='mt-0 mb-0'>Department of Commerce</p>
                            <a href='https://www.doc.gov.np' target='blank'>www.doc.gov.np</a>

                            {/* 4 */}
                            <hr className='mt-2 mb-2' />
                            <p className='mt-0 mb-0'>Department of Industry</p>
                            <a href='https://www.doi.gov.np' target='blank'>www.doi.gov.np</a>

                            {/* 5 */}
                            <hr className='mt-2 mb-2' />
                            <p className='mt-0 mb-0'>Department of Customs </p>
                            <a href='https://www.customs.gov.np' target='blank'>www.customs.gov.np</a>


                            {/* 6 */}
                            <hr className='mt-2 mb-2' />
                            <p className='mt-0 mb-0'>The Institute of Chartered Accountants of Nepal</p>
                            <a href='https://www.ican.org.np' target='blank'>www.ican.org.np</a>
                            <br />

                            {/* 7 */}
                            <hr className='mt-2 mb-2' />
                            <p className='mt-0 mb-0'>Nepal Rastra Bank</p>
                            <a href='https://www.nrb.org.np' target='blank'>www.nrb.org.np</a>

                            {/* 8 */}
                            <hr className='mt-2 mb-2' />
                            <p className='mt-0 mb-0'>Nepal Government Portal</p>
                            <a href='https://www.nepal.gov.np' target='blank'>www.nepal.gov.np</a>

                            {/* 9 */}
                            <hr className='mt-2 mb-2' />
                            <p className='mt-0 mb-0'>Nepal Law Commission</p>
                            <a href='http://www.lawcommission.gov.np/en/' target='blank'>www.lawcommission.gov.np</a>

                            {/* 10 */}
                            <hr className='mt-2 mb-2' />
                            <p className='mt-0 mb-0'>Auditing Standards Board</p>
                            <a href='http://www.ausb.org.np/ausb/' target='blank'>www.ausb.org.np</a>

                            {/* 11 */}
                            <hr className='mt-2 mb-2' />
                            <p className='mt-0 mb-0'>The Institute of Chartered Accountants of India</p>
                            <a href='http://www.icai.org/' target='blank'>www.icai.org</a>


                        </div>
                    </div>
                </div>


                {/* Body ends */}



                {/* footer div start*/}
                <div>
                    <Footer />
                </div>
                {/* footer div end*/}
            </div>
        );
    }
}
export default ImpLinks;

