import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"


class Footernocard extends Component {


    getYear() {
        return new Date().getFullYear();
    }
    render() {

        return (

            <div className="bg-light">
                <footer>
                    <div className="container pt-5 border-bottom footerbody">
                        <div className="row">

                            <div className="col-md-12 col-sm-12">

                                <div className="col-md-3 col-sm-6 col-6 p-0 float-left mb-3">
                                    <h5 className="mb-4 font-weight-bold text-uppercase">Quick Links</h5>
                                    <ul className="list-group">
                                        <Link to='/'><li className="list-group-item bg-transparent border-0 p-0 mb-2"> Home</li></Link>
                                        <Link to='/ourservices'><li className="list-group-item bg-transparent border-0 p-0 mb-2"> Our services</li></Link>
                                        <Link to='/aboutus'><li className="list-group-item bg-transparent border-0 p-0 mb-2"> About us</li></Link>
                                        <Link to='/careers'> <li className="list-group-item bg-transparent border-0 p-0 mb-2"> Careers</li></Link>
                                        <Link to='/contactus'><li className="list-group-item bg-transparent border-0 p-0 mb-2"> Contact Us</li></Link>
                                    </ul>
                                </div>


                                <div className="col-md-3 col-sm-6 col-6 p-0 float-left mb-3">
                                    <h5 className="mb-4 font-weight-bold text-uppercase">Solutions</h5>
                                    <ul className="list-group">
                                        <Link to='/financialconsultancy'> <li className="list-group-item bg-transparent border-0 p-0 mb-2"> Financial Consulting  </li></Link>
                                        <Link to='/taxconsultant'> <li className="list-group-item bg-transparent border-0 p-0 mb-2"> Tax Consultant </li></Link>
                                        <Link to='/riskadvisory'> <li className="list-group-item bg-transparent border-0 p-0 mb-2">  Risk Advisory </li></Link>
                                        <Link to='/payrollandaccountng'> <li className="list-group-item bg-transparent border-0 p-0 mb-2"> Payroll and Accounting </li></Link>
                                        <Link to='/legal'> <li className="list-group-item bg-transparent border-0 p-0 mb-2"> Legal  </li></Link>
                                        <Link to='/audit'> <li className="list-group-item bg-transparent border-0 p-0 mb-2"> Audit  </li></Link>
                                    </ul>
                                </div>

                                <div className="col-md-3 col-sm-6 col-6 p-0 mb-3 float-left">
                                    <h5 className="mb-4 font-weight-bold text-uppercase">Contact Us</h5>
                                    <ul className="list-group">
                                    <li className="list-group-item bg-transparent border-0 p-0 mb-2"> B.S.P Proficiency Private Limited </li>
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2"> Beside V.S. Niketan School Aloknagar</li>
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2"> Baneshwor, Kathmandu, Nepal </li>
                                        <li className="list-group-item bg-transparent border-0 p-0  "> Call :984-5212409 / 984-5212387 </li>
                                        {/* <li className="list-group-item bg-transparent border-0 p-0 mb-2">  &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 984-5212387 </li> */}
                                    </ul>
                                </div>



                                <div className="col-md-3 col-sm-6 col-6 mb-3 pl-0 float-left">
                                    <h5 className="mb-4 font-weight-bold text-uppercase">Connect</h5>
                                    <ul className="list-group">
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <a href="https://www.facebook.com/BSP-Proficiency-Private-Limited-105284987612560/" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook mr-1"></i> Facebook </a>
                                        </li>
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <i className="fa fa-linkedin mr-1"></i> LinkedIn
                                        </li>
                                        <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <i className="fa fa-twitter mr-1"></i> Twitter  </li>
                                        {/* <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                                            <a href="#" target="_blank"><i className="fa fa-youtube mr-1"></i> YouTube 
                                        </li> */}
                                    </ul>
                                </div>

                            </div>
                            <div className="col-md-12">
                                <div className="py-4 d-flex justify-content-center align-items-center">
                                    &copy; BSP Proficiency Pvt. Ltd.  &nbsp; {this.getYear()}
                                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.linkedin.com/in/santosh-subedi-b38a93152/" target="_blank" rel="noopener noreferrer">Designed and Created By: Santosh Subedi</a>
                                </div>
                            </div>
                        </div >
                    </div >
                </footer >
            </div >

        );
    }
}
export default Footernocard;
