import React, { Component } from 'react';
// import Swal from 'sweetalert2'
import Swal from 'sweetalert2'
// import { Link } from 'react-router-dom';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"


class Comingsoon extends Component {
    constructor(props) {
        super(props);
        this.alert = this.alert.bind(this);

    }

    alert(e) {


        // Swal.fire(
        //     {icon: 'error',
        //     title: 'B.S.P Proficiency Private Limited',
        //     text: 'This part of website is under construction!',
        //     footer: '<a href="/">Back to home page</a>'
        // }
        // )

        Swal.fire({
            title: '<strong>B.S.P Proficiency Private Limited</strong>',
            icon: 'error',
            html:
              'This part of website is under construction!',
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonText:
              '<a href="/" classname="text-dark"> Back to home page!</a>',
              confirmButtonColor: "rgb(153, 221, 85)"
          })


        //getting all vendor data

    }
    componentDidMount() {
        this.alert()
    }
    render() {


        return (
            <div className='container   '>
                <div className='row   '>

                    <div className='col-md-4'>
                        <article>
                            <h1>We&rsquo;ll be back soon!</h1>
                            <div>
                                <p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. If you need to you can always <a href="mailto:#">contact us</a>, otherwise we&rsquo;ll be back online shortly!</p>
                                <p>&mdash; The Team</p>
                            </div>
                        </article>
                    </div >
                </div >
            </div >

        );
    }
}
export default Comingsoon;

