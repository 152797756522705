import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"


class Body extends Component {



    render() {
        const nounderline = {
            textDecoration: 'none'
        };
        
        const businessadvisory = {
            backgroundImage: 'url( images/ourservices/business-advisory.jpg)',
            backgroundSize: '100% 100%'
        };

        const financialconsulting = {
            backgroundImage: 'url( images/ourservices/financialconsulting.jpg)',
            backgroundSize: '100% 100%'
        };
        const aap = {
            backgroundImage: 'url( images/ourservices/accountingpayroll.jpg)',
            backgroundSize: '100% 100%'
        };
        const tax = {
            backgroundImage: 'url( images/ourservices/taxconsultant.png)',
            backgroundSize: '100% 100%'
        };
        const riskadvisory = {
            backgroundImage: 'url( images/ourservices/riskadvisory.jpg)',
            backgroundSize: '100% 100%'
        };
        const legal = {
            backgroundImage: 'url( images/ourservices/legal.jpg)',
            backgroundSize: '100% 100%'
        };
        return (

            <div>

                {/* <!-- Icons Grid --> */}
                <section className="features-icons bg-light text-center">
                    <div className="container">

                        <div className="row">

                            {/* Business Advisory--------------  */}
                            <div className="col-lg-4">
                                <Link to='/businessadvisory' style={nounderline}>

                                    <div className="features-icons-item mx-auto mb-0 mb-lg-3 text-dark " >
                                        <div className=" features-icons-icon d-flex iconimagebackgrund" style={businessadvisory}>
                                        </div>
                                        <h3>Business Advisory </h3>
                                        {/* <p className="lead mb-0">Capital restructuring, loan Syndication, Merger and Acquisition, strategic alliances, strategic sale of entity </p> */}
                                    </div>
                                </Link>
                            </div>

                            {/* Financial Consulting */}
                            <div className="col-lg-4">
                                <Link to='/financialconsultancy' style={nounderline}>

                                    <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3 text-dark" >
                                        <div className=" features-icons-icon d-flex iconimagebackgrund" style={financialconsulting}>
                                        </div>
                                        <h3>Financial Consulting</h3>
                                        {/* <p className="lead mb-0"> We provide financial advice in order to improve financial strategy, create more efficient procedures, and ultimately maximize revenue </p> */}
                                    </div>
                                </Link>
                            </div>

                            {/* Accounting and Payroll */}
                            <div className="col-lg-4">
                                <Link to='/payrollandaccountng' style={nounderline}>

                                    <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3 text-dark">
                                        <div className=" features-icons-icon d-flex iconimagebackgrund" style={aap}>
                                        </div>
                                        <h3>Accounting and Payroll</h3>
                                        {/* <p className="lead mb-0">We are providing payroll management services. </p> */}
                                    </div>
                                </Link>
                            </div>





                        </div>
                        <div className="row">
                            {/* Tax Consultant */}
                            <div className="col-lg-4 margintop10">
                                <Link to='/taxconsultant' style={nounderline}>

                                    <div className="features-icons-item mx-auto mb-0 mb-lg-3 text-dark" >
                                        <div className=" features-icons-icon d-flex iconimagebackgrund" style={tax}>
                                        </div>
                                        <h3>Tax Consulting</h3>
                                        {/* <p className="lead mb-0">We provide Direct and Indirect Tax Consulting </p> */}
                                    </div>
                                </Link>
                            </div>

                            {/* Risk Advisory */}
                            <div className="col-lg-4 margintop10">
                                <Link to='/riskadvisory' style={nounderline}>

                                    <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3 text-dark" >
                                        <div className=" features-icons-icon d-flex iconimagebackgrund" style={riskadvisory}>
                                        </div>
                                        <h3>Risk Advisory</h3>

                                        {/* <p className="lead mb-0">We help to manage risk and uncertainty</p> */}
                                    </div>
                                </Link>
                            </div>

                            {/* Others */}
                            <div className="col-lg-4 margintop10">
                                <Link to='/others' style={nounderline}>

                                    <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3 text-dark">
                                        <div className=" features-icons-icon d-flex iconimagebackgrund " style={legal}>
                                        </div>
                                        <h3>Others Services</h3>
                                        {/* <p className="lead mb-0"> Other Services  </p> */}
                                    </div>
                                </Link>
                            </div>





                        </div>

                    </div>
                </section>






            </div >

        );
    }
}
export default Body;
