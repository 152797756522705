import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"


class NavBar extends Component {



    render() {

        return (

            <div>
                <nav className="navbar navbar-dark navbartextcolor navbar-expand-lg    " >
                    {/* <div className="container"> */}
                        <Link to='/' > <img className="d-block carouselimage  " src="images/logo.png" alt="First slide" style={{ height: "60px", maxWidth:"299px", "margin-left":"32px"}} /> </Link>
                        <button className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <ul className="navbar-nav ml-auto font-weight-bolder text-black">
                                <li className="navbar-item">
                                    <Link to='/' className="nav-link text-success mr-3"> Home  </Link>

                                </li>

                                <li className="navbar-item">
                                    <Link to='/aboutus' className="nav-link text-success mr-3"> About Us  </Link>

                                </li>

                                <li className="nav-item dropdown">
                                    <Link to='/ourservices' className="nav-link text-success dropdown-toggle mr-3" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Our Services </Link>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Link to='/businessadvisory' className="dropdown-item">Business Advisory</Link>
                                        <Link to='/financialconsultancy' className="dropdown-item">Financial Consulting </Link>
                                        <Link to='/payrollandaccountng' className="dropdown-item">Accounting and Payroll</Link>
                                        <Link to='/taxconsultant' className="dropdown-item">Tax Consultant</Link>
                                        <Link to='/riskadvisory' className="dropdown-item">Risk Advisory</Link>
                                        <Link to='/others' className="dropdown-item"> Others </Link>
                                    </div>
                                </li>

                                <li className="navbar-item mr-5">
                                    <Link to='/contactus' className="nav-link text-success mr-3"> Contact us </Link>
                                </li>
                            </ul>
                        </div>
                    {/* </div> */}
                </nav>



            </div>
        );
    }
}
export default NavBar;

















