import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"


class Sidebar extends Component {
  render() {

    return (
      <div>
        <div class="sidenav  text-left">
          <Link to='/businessadvisory' className="font-weight-normal"> Business Advisory  </Link> 
          <Link to='/financialconsultancy' className="font-weight-normal"> Financial Consulting </Link> 
          <Link to='/payrollandaccountng' className="font-weight-normal">Accounting and Payroll   </Link> 
          <Link to='/taxconsultant' className="font-weight-normal"> Tax Consultant</Link>  
          <Link to='/riskadvisory' className="font-weight-normal"> Risk Advisory</Link> 
          <Link to='/others' className="dropdown-item"> Others </Link>

        </div>
      </div>
    );
  }
}
export default Sidebar;
