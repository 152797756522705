import React, { Component } from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"
import NavBar from "../NavBar"
import Slider from '../homepage/slider';
import Footer from '../footer';
import Body from '../homepage/hpbody';



class Homepage extends Component {



    render() {
        // const sliderdiv = {
        //                 height: '50px',
        //                 maxHeight: '200px',
        //                 backgroundSize: 'cover',
        //                 backgroundPosition: 'center',
        //                 backgroundRepeat: 'no-repeat',
        //             };

        return (

            <div className="bg-light">
                {/* navbar div start */}
                <div>
                    <NavBar />
                </div>
                {/* navbar div end */}
                 

                <div>
                    <Slider/>
                </div>
                
                <Body/>

                {/* footer div start*/}
                <div>
                <Footer/>
                </div>
                {/* footer div end*/}

            </div>
        );
    }
}
export default Homepage;

