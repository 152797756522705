import React, { Component } from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"
import NavBar from "../NavBar"
import Footer from '../footer';
import Sidebar from '../servicessidebar';


class PayrollandAccounting extends Component {



    render() {


        // const legal = {
        //     backgroundImage: 'url( images/legal.jpg)',
        //     backgroundSize: '92%'

        // };


        return (

            <div className='bg-light'>

                {/* navbar div start */}
                <div>
                    <NavBar />
                </div>
                {/* navbar div end */}

                <div className="container">

                    <div class="row">

                        <div class="col-md-3 col-sm-3">

                            <Sidebar />
                        </div>
                        <div class="col-md-9 col-sm-9">
                            <section className="services-image bg-light text-left">
                                <div className="row">

                                    {/* legal */}
                                    <div className=" mb-5 mb-lg-0 mb-lg-3">
                                        {/* <div className=" features-icons-icon d-flex" style={legal}>
                                            </div> */}
                                        <h3>  Accounting and Payroll</h3>
                                        <p className="lead mb-0">We  provide following services related to payroll and accounting as your requirement and needs: </p>

                                        <ul className='lead'>
                                            <li>Maintaining Books of Account in accordance with Accounting Standards.</li>
                                            <li>Preparation of annual financial statements, Income tax return and tax balance, other returns and standard annual reports, and annual reports according to your needs.</li>
                                            <li>Preparing employee's payrolls, including calculation of salaries, attendance records etc.</li>
                                            <li>Preparing and filing monthly VAT, TDS returns.</li>
                                        </ul>
                                    </div>

                                </div>
                            </section>
                        </div>
                    </div>
                </div>



                {/* footer div start*/}
                <div>
                    <Footer />
                </div>
                {/* footer div end*/}




            </div >

        );
    }
}
export default PayrollandAccounting;
