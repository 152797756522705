import React, { Component } from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"
import NavBar from "../NavBar"
import Footer from '../footer';
import Sidebar from '../aboutussidebar';
// import Sidebar from '../servicessidebar';


class Missionvision extends Component {



    render() {


        // const listnone = {
        //     listStyleType: 'none'
        // };




        return (

            <div className='bg-light'>

                {/* navbar div start */}
                <div>
                    <NavBar />
                </div>
                {/* navbar div end */}

                <div className="container">

                    <div class="row">

                        <div class="col-md-3 col-sm-3">

                            <Sidebar />
                        </div>
                        <div class="col-md-9 col-sm-9">
                            <section className="services-image bg-light text-left">
                                <div className="row">

                                    <div className="   mb-5 mb-lg-0 mb-lg-3">

                                        <h2>Our value defines who we are,</h2>
                                        <p className="lead mb-0"> For us this means to always:</p>
                                        <ol className="lead mb-0"  >

                                            <li>The interest of our client is always in upmost priority.</li>
                                            <li>The important to maintain the Secrecy of client information has always our prime concern</li>
                                            <li>The service we deliver is the best of our knowledge and experience we hold to every client as cost effectively as we can.</li>
                                        </ol>

                                    </div>


                                </div>
                            </section>
                        </div>
                    </div>
                </div>



                {/* footer div start*/}
                <div>
                    <Footer />
                </div>
                {/* footer div end*/}


            </div >

        );
    }
}
export default Missionvision;
