import React, { Component } from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"
import NavBar from "../NavBar"
import Footer from '../footer';
import Sidebar from '../servicessidebar';


class Others extends Component {



    render() {

         
        // const legal = {
        //     backgroundImage: 'url( images/legal.jpg)',
        //     backgroundSize: '92%'

        // };
         

        return (

            <div className='bg-light'>

                {/* navbar div start */}
                <div>
                    <NavBar />
                </div>
                {/* navbar div end */}

                <div className="container">

                    <div class="row">

                        <div class="col-md-3 col-sm-3">

                            <Sidebar />
                        </div>
                        <div class="col-md-9 col-sm-9">
                            <section className="  services-image bg-light text-left">
                                    <div className="row">

                                        {/* legal */}
                                        <div className=" mb-5 mb-lg-0 mb-lg-3">
                                            {/* <div className=" features-icons-icon d-flex" style={legal}>
                                            </div> */}
                                            <h3>Others</h3>
                                            <p className="lead mb-0">We are providing all type of Others services relating to business concerns like:</p>
                                       <ul className="lead mb-0">
                                           <li>Registration of Company, Firm, NGO etc</li>
                                           <li>Registration of PAN & VAT </li>
                                           <li>Registration of Patent & Trademark</li>
                                           <li>Obtain License for different sector & EXIM Code   </li>
                                           <li>Legal Services</li>
                                       </ul>
                                        </div>

                                    </div>
                            </section>
                        </div>
                    </div>
                </div>



                {/* footer div start*/}
                <div>
                    <Footer />
                </div>
                {/* footer div end*/}




            </div >

        );
    }
}
export default Others;
