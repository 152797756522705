import React, { Component } from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"
import NavBar from "../NavBar"
import Footer from '../footer';
import Sidebar from '../servicessidebar';


class RiskAdvisory extends Component {



    render() {

         
        // const legal = {
        //     backgroundImage: 'url( images/legal.jpg)',
        //     backgroundSize: '92%'

        // }
        return (

            <div className='bg-light'>

                {/* navbar div start */}
                <div>
                    <NavBar />
                </div>
                {/* navbar div end */}

                <div className="container">

                    <div class="row">

                        <div class="col-md-3 col-sm-3">

                            <Sidebar />
                        </div>
                        <div class="col-md-9 col-sm-9">
                            <section className="services-image bg-light text-left">
                                    <div className="row">

                                        {/* legal */}
                                        <div className=" mb-5 mb-lg-0 mb-lg-3">
                                            {/* <div className=" features-icons-icon d-flex" style={legal}>
                                            </div> */}
                                            <h3>Risk Advisory</h3>
                                            <p className="lead mb-0">Complex financial instruments in conjunction
                                             with market volatility increase your financial risk with sudden exposure
                                              to large losses in asset prices or earnings and managing governance, 
                                              risk and compliance are similar challenges to any organization. We offer 
                                              integrated services and ensure synergy in our solutions.</p>
                                              <ul className="lead mb-0">
                                                  <li>Compliance and Regulatory Risk Management</li>
                                                  <li>Governance and Operational Risk Management</li>
                                                  <li>Credit Risk Management</li>
                                                  <li>Hedge Optimizations</li>
                                              </ul>
                                        </div>

                                    </div>
                            </section>
                        </div>
                    </div>
                </div>



                {/* footer div start*/}
                <div>
                    <Footer />
                </div>
                {/* footer div end*/}




            </div >

        );
    }
}
export default RiskAdvisory;
