import React, { Component } from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"
import NavBar from "../NavBar"
import Footer from '../footer';
import Sidebar from '../aboutussidebar';
import Swal from 'sweetalert2'


class Ourstory extends Component {
    constructor(props) {
        super(props);
        this.alert = this.alert.bind(this);

    }

    alert(e) {


        // Swal.fire(
        //     {icon: 'error',
        //     title: 'B.S.P Proficiency Private Limited',
        //     text: 'This part of website is under construction!',
        //     footer: '<a href="/">Back to home page</a>'
        // }
        // )

        Swal.fire({
            title: '<strong>B.S.P Proficiency Private Limited</strong>',
            icon: 'error',
            html:
              'This part of website is under construction!',
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonText:
              '<a href="/" classname="text-dark"> Back to home page!</a>',
              confirmButtonColor: "rgb(153, 221, 85)"
          })


        //getting all vendor data

    }
    componentDidMount() {
        this.alert()
    }


    render() {


 
        // const ourstory = {
        //     backgroundImage: 'url( images/ourstory.jpg)',
        //     backgroundSize: '100% 100%'

        // };


        return (

            <div className='bg-light'>

                {/* navbar div start */}
                <div>
                    <NavBar />
                </div>
                {/* navbar div end */}

                <div className="container">

                    <div class="row">

                        <div class="col-md-3 col-sm-3">

                            <Sidebar />
                        </div>
                        <div class="col-md-9 col-sm-9">
                            <section className="services-image bg-light text-left">
                                <div className="row">

                                    {/* legal */}
                                    <div className="   mb-5 mb-lg-0 mb-lg-3">
                                         
                                        <h3>Our story</h3>
                                        <p className="lead mb-0">Our story</p>

                                        
                                    </div>

                                </div>
                            </section>
                        </div>
                    </div>
                </div>



                {/* footer div start*/}
                <div>
                    <Footer />
                </div>
                {/* footer div end*/}


            </div >

        );
    }
}
export default Ourstory;
