import React, { Component } from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"
import NavBar from "../NavBar"
import Footer from '../footer';
import Sidebar from '../aboutussidebar';
// import Sidebar from '../servicessidebar';


class Ourteam extends Component {



    render() {
        const maxheight = {
            maxHeight: '382px '
        };

        return (

            <div className='bg-light'>

                {/* navbar div start */}
                <div>
                    <NavBar />
                </div>
                {/* navbar div end */}

                <div className="container">

                    <div class="row">

                        <div class="col-md-3 col-sm-3">

                            <Sidebar />
                        </div>
                        <div class="col-md-9 col-sm-9">

                            {/* <!-- Page Content --> */}
                            <div class="container">
                                <div class="row">
                                    {/* <!-- Team Member 1 --> */}
                                    <div class="col-xl-6 col-md-6 mb-4 mt-2">
                                        <div class="card border-0 shadow">
                                            <img src="images/teammember/pandey.jpg" class="card-img-top" alt="..." style={maxheight} />
                                            <div class="card-body text-center">
                                                <h5 class="card-title mb-0">Sandeep Pandey </h5>
                                                <div class="card-text text-black-50">CEO</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Team Member 2 --> */}
                                    <div class="col-xl-6 col-md-6 mb-4 mt-2">
                                        <div class="card border-0 shadow">
                                            <img src="images/teammember/bibekg.jpg" class="card-img-top" alt="..." style={maxheight} />
                                            <div class="card-body text-center">
                                                <h5 class="card-title mb-0"> Bibek Ghaire .</h5>
                                                <div class="card-text text-black-50">Director</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Team Member 3 --> */}
                                    <div class="col-xl-6 col-md-6 mb-4 mt-2">
                                        <div class="card border-0 shadow">
                                            <img src="images/teammember/prem.jpg" class="card-img-top" alt="..." style={maxheight} />
                                            <div class="card-body text-center">
                                                <h5 class="card-title mb-0">Prem Prasad Devkota </h5>
                                                <div class="card-text text-black-50">Director</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Team Member 4 --> */}
                                    <div class="col-xl-6 col-md-6 mb-4 mt-2">
                                        <div class="card border-0 shadow">
                                            <img src="images/teammember/madanbasnet.png" class="card-img-top" alt="..." style={maxheight} />
                                            <div class="card-body text-center">
                                                <h5 class="card-title mb-0">Madan Basnet</h5>
                                                <div class="card-text text-black-50">Director</div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- Team Member 5 --> */}
                                    <div class="col-xl-6 col-md-6 mb-4 mt-2">
                                        <div class="card border-0 shadow">
                                            <img src="images/teammember/jeevanmagar.jpg" class="card-img-top" alt="..." style={maxheight} />
                                            <div class="card-body text-center">
                                                <h5 class="card-title mb-0">Jeevan Pun Magar</h5>
                                                <div class="card-text text-black-50">Executive Member</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /.row --> */}

                            </div>
                        </div>

                        {/* <div class="col-md-9 col-sm-9">
                            <section className="features-icons bg-light text-center">
                                <div className="row">

                                     
                                        <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                                            <div className=" features-icons-icon d-flex" style={ourteam}>
                                            </div>
                                            <h3>Ourteam</h3>
                                            <p className="lead mb-0">We are providing all type of legal services relating to business concerns</p>
                                        </div>

                                    </div>
                            </section>
                            </div> */}
                    </div>
                </div>



                {/* footer div start*/}
                <div>
                    <Footer />
                </div>
                {/* footer div end*/}


            </div >

        );
    }
}
export default Ourteam;
