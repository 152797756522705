import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"


class Slider extends Component {
    

    render() {

        const nounderline = {
            textDecoration: 'none' ,
            fontColor:'black'

        };
        return (
            // <div className='container   '>
            <div className='row   '>
                {/* <div > */}


                <div className='col-md-8 '>
                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-interval="3000">
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                        </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img
                                    src="images/bannerimage/businessadvivisory.png"
                                    className="d-block carouselimage"
                                    alt="First slide"
                                />
                                <div className="carousel-caption d-none d-md-block">


                                    <div className="d-flex justify-content-center">
                                        <div className="p-2">
                                            <div className=" card">
                                                <div className="card text-dark">
                                                    <div className="card-body text-center bg-success">
                                                    <Link to='/businessadvisory' style={nounderline} > <h5 className="font-weight-bold text-dark">Business Consultant</h5></Link>
                                                        {/* <p>First slide</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                </div>
                            </div>



                            <div className="carousel-item">
                                <img
                                    src="images/bannerimage/financialconsultancy.png"
                                    className="d-block carouselimage"
                                    alt="second slide"
                                />
                                <div className="carousel-caption d-none d-md-block">
                                    <div className="d-flex justify-content-center">
                                        <div className="p-2">
                                            <div className="card">
                                                <div className="card text-dark">
                                                    <div className="card-body text-center bg-success">
                                                    <Link to='/financialconsultancy' style={nounderline} > <h5 className="font-weight-bold text-dark">Financial Consulting</h5></Link>
                                                        {/* <p>Second slide</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item">
                                <img
                                    src="images/bannerimage/payrollandaccountng.png"
                                    className="d-block carouselimage"
                                    alt="third slide"
                                />
                                <div className="carousel-caption d-none d-md-block">
                                    <div className="d-flex justify-content-center">
                                        <div className="p-2">
                                            <div className="card">
                                                <div className="card text-dark">
                                                    <div className="card-body text-center bg-success">
                                                    <Link to='/payrollandaccountng' style={nounderline} > <h5 className="font-weight-bold text-dark">Accounting and Payroll</h5></Link>
                                                        {/* <p>Second slide</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img
                                    src="images/bannerimage/taxconsultant.png"
                                    className="d-block carouselimage"
                                    alt="fourth slide"
                                />
                                <div className="carousel-caption d-none d-md-block">
                                    <div className="d-flex justify-content-center">
                                        <div className="p-2">
                                            <div className="card">
                                                <div className="card text-dark">
                                                    <div className="card-body text-center bg-success">
                                                    <Link to='/taxconsultant' style={nounderline} > <h5 className="font-weight-bold text-dark">Tax Consultant</h5></Link>
                                                        {/* <p>Third slide</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img
                                    src="images/bannerimage/riskadvisory.png"
                                    className="d-block carouselimage"
                                    alt="fifth slide"
                                />
                                <div className="carousel-caption d-none d-md-block">
                                    <div className="d-flex justify-content-center">
                                        <div className="p-2">
                                            <div className="card">
                                                <div className="card text-dark">
                                                    <div className="card-body text-center bg-success">
                                                    <Link to='/riskadvisory' style={nounderline} > <h5 className="font-weight-bold text-dark">Risk Advisory</h5></Link>
                                                        {/* <p>Fourth slide</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
















                    </div>
                </div>
                <div className='col-md-4'>
                    <h3 className='fontcolor font-weight-bold mt-4'>Important Links</h3>

                    {/* 1 */}
                    <hr />
                    <p className='mt-0 mb-0'>Inland Revenue Department</p>
                    <a href='https://www.ird.gov.np' target='blank'>www.ird.gov.np</a>

                    {/* 2 */}
                    <hr className='mt-2 mb-2' />
                    <p className='mt-0 mb-0'>Office of the Company Registrar</p>
                    <a href='https://www.ocr.gov.np' target='blank'>www.ocr.gov.np</a>


                    {/* 3 */}
                    <hr className='mt-2 mb-2' />
                    <p className='mt-0 mb-0'>Department of Commerce</p>
                    <a href='https://www.doc.gov.np' target='blank'>www.doc.gov.np</a>

                    {/* 4 */}
                    <hr className='mt-2 mb-2' />
                    <p className='mt-0 mb-0'>Department of Industry</p>
                    <a href='https://www.doi.gov.np' target='blank'>www.doi.gov.np</a>

                    {/* 5 */}
                    <hr className='mt-2 mb-2' />
                    <p className='mt-0 mb-0'>Department of Customs </p>
                    <a href='https://www.customs.gov.np' target='blank'>www.customs.gov.np</a>


                    {/* 6 */}
                    <hr className='mt-2 mb-2' />
                    <p className='mt-0 mb-0'>The Institute of Chartered Accountants of Nepal</p>
                    <a href='https://www.ican.org.np' target='blank'>www.ican.org.np</a>
                    <br />

                    <Link to="/implink" ><p className="card-text  text-center"><small>All Links</small> </p> </Link>

                    {/* <Link to='/implink'   ><small className="text-right">All Links</small></Link> */}

                </div>
                {/* </div> */}
            </div>
            // </div>

        );
    }
}
export default Slider;

