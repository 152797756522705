import React, { Component } from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"
import NavBar from "../NavBar"
import Footer from '../footer';
import Body from '../ourservices/osbody';




class Ourservices extends Component {



    render() {

        return (

            <div className="bg-light">
                <div>
                    <NavBar />
                </div>


                <Body/>
                <div>
                    <Footer/>
                </div>
                {/* footer div end*/}

            </div>
        );
    }
}
export default Ourservices;

