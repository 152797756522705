import React, { Component } from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"
import NavBar from "../NavBar"
import Footer from '../footer';
import Sidebar from '../servicessidebar';


class FinancialConsultancy extends Component {



    render() {

        // const aap = {
        //     backgroundImage: 'url( images/accountingpayroll.jpg)',
        //     backgroundSize: '92%'
        // };
         

        return (

            <div className='bg-light'>

                {/* navbar div start */}
                <div>
                    <NavBar />
                </div>
                {/* navbar div end */}

                <div className="container">

                    <div class="row">

                        <div class="col-md-3 col-sm-3">

                            <Sidebar />
                        </div>
                        <div class="col-md-9 col-sm-9">
                            <section className="services-image bg-light text-left">
                                    <div className="row">

                                        {/* legal */}
                                        <div className=" mb-5 mb-lg-0 mb-lg-3">
                                            {/* <div className=" features-icons-icon d-flex" style={aap}>
                                            </div> */}
                                            <h3>Financial Consulting</h3>
                                            <p className="lead mb-0">BSP provide financial advice in order to improve financial 
                                            strategy, create more efficient procedures, and ultimately maximize revenue. With an 
                                            in-depth understanding of the fiscal world, our team carefully examines monetary 
                                            aspects of businesses and provide following services:</p>
                                            <ul className="lead mb-0">
                                              <li>Perform financial analysis, reporting and management activities</li>
                                              <li>Review the accounting procedures and recommend improvements</li>
                                              <li>Develop and submit annual budget plan and finance plan</li>
                                              <li>Understand client needs through regular interaction and recommend financial solutions accordingly</li>
                                              <li>Monitor and Review</li>
                                          </ul>
                                        </div>

                                    </div>
                            </section>
                        </div>
                    </div>
                </div>



                {/* footer div start*/}
                <div>
                    <Footer />
                </div>
                {/* footer div end*/}




            </div >

        );
    }
}
export default FinancialConsultancy;
