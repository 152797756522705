import React, { Component } from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"
import NavBar from "../NavBar"
import Footer from '../footer';
import Sidebar from '../servicessidebar';


class TaxConsultant extends Component {



    render() {


        // const legal = {
        //     backgroundImage: 'url( images/legal.jpg)',
        //     backgroundSize: '92%'

        // }

        return (

            <div className='bg-light'>

                {/* navbar div start */}
                <div>
                    <NavBar />
                </div>
                {/* navbar div end */}

                <div className="container">

                    <div class="row">

                        <div class="col-md-3 col-sm-3">

                            <Sidebar />
                        </div>
                        <div class="col-md-9 col-sm-9">
                            <section className="services-image bg-light text-left">
                                <div className="row">

                                    {/* legal */}
                                    <div className=" mb-5 mb-lg-0 mb-lg-3">
                                        {/* <div className=" features-icons-icon d-flex" style={legal}>
                                            </div> */}
                                        <h3>TaxConsultant</h3>
                                        <p className="lead mb-0">We try to minimize the effects of numerous tax aspects
                                         which clients face, at the same time taking into account that they observe tax-related
                                          as well as all other laws and regulations. In case of tax supervision or dispute we
                                          consult our clients with regard to ensuring their successful outcome. By constantly
                                          monitoring and tracking changes in tax laws, our tax experts can give you exactly what
                                              you need, timely and accurate usage information about:</p>
                                        <ul className="lead mb-0">
                                            <li>Income tax</li>
                                            <li>Value Added Tax</li>
                                            <li>Excise & Customs</li>
                                            <li>Other taxes and laws</li>

                                        </ul>
                                    </div>

                                </div>
                            </section>
                        </div>
                    </div>
                </div>



                {/* footer div start*/}
                <div>
                    <Footer />
                </div>
                {/* footer div end*/}




            </div >

        );
    }
}
export default TaxConsultant;
