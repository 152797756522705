import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"


class Sidebar extends Component {
  
  render() {
    const nounderline = {
      textDecoration: 'none',
  };
    return (
      <div>
        <div class="sidenav  text-left">
          <Link to='/ourteam' className="font-weight-normal " style={nounderline}>Our Team</Link> 
          <Link to='/missionvision' className="font-weight-normal ">  Mission Vission</Link> 
          <Link to='/ourstory' > Our Story</Link> 
        </div>
      </div>
    );
  }
}
export default Sidebar;
