import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"


class Body extends Component {
    // constructor(props) {
    //     super(props);
    //     this.alert = this.alert.bind(this);

    // }

    // alert(e) {
    //       Swal.fire({
    //         position: 'center',
    //         icon: 'success',
    //         title: 'B.S.P Proficiency Private Limited!!',
    //         text: 'This   website is under construction!',
    //         showConfirmButton: false,
    //         timer: 3000
    //       })
    // }
    // componentDidMount() {
    //     this.alert()
    // }


    render() {

        // const aap = {
        //     backgroundImage: 'url( images/accountingpayroll.jpg)',
        //     backgroundSize: '100% 100%'
        // };
        // const legal = {
        //     backgroundImage: 'url( images/legal.jpg)',
        //     backgroundSize: '100% 100%'

        // };
        // const audit = {
        //     backgroundImage: 'url( images/audit.png)',
        //     backgroundSize: '100% 100%'

        // };
        // const bg1 = {
        //     backgroundImage: 'url( images/team.jpg)'
        // };
        // const why = {
        //     backgroundImage: 'url( images/why.png)'
        // };
        // const howwework = {
        //     backgroundImage: 'url( images/howwework.png)'
        // };

        // const listnone = {
        //     listStyleType: 'none'
        // };
        const maxheight = {
            maxHeight: '490px'
        };

        const maxheight404 = {
            maxHeight: '404px'
        };
        // const nounderline = {
        //     textDecoration: 'none',
        // };
        const paddingshowcasetext = {
            // padding: '7rem 3rem 6rem 2rem',
            paddingLeft: '60px',
            paddingRight: '20px'
        };
        return (

            <div>

                {/*<!-- Image Showcases --> */}
                <section className="showcase">
                    <div className="container p-0">
                        <div className="row no-gutters">

                            {/* <div className="col-lg-6 order-lg-2 text-white showcase-img" style={bg1}> */}
                            <div className="col-lg-6 order-lg-2 text-white  ">
                                {/* <img className="groupteamphoto" alt='team members' src='images/team.jpg'/> */}
                                <img style={maxheight404} className='  mr-5' alt='team members' src='images/team.jpg' />
                            </div>
                            <div className="col-lg-6 order-lg-1 my-auto   text-left" style={paddingshowcasetext}>
                                {/* <div className="col-lg-6 order-lg-1 my-auto   text-left" > */}
                                <h2 className="text-left">Welcome to  B.S.P Proficiency Private Limited </h2>
                                <h5 className="  lead text-justify">BSP is a brand that is in the business of positive transformation of individuals and organizations.
                                The key to our working style is partnership. We start by understanding the challenges that your business is
                                        facing to provide the precise consultancy support you require, and work with you to propose solutions that answer your needs. <Link to='/aboutus'> read more...</Link>
                                </h5>


                            </div>
                        </div>
                        <div className="row no-gutters">
                            {/* <div className="col-lg-6 text-white showcase-img" style={why}></div> */}
                            <div className="col-lg-6 text-white mt-4 mb-2  showcase-img"  >
                                <Link to='/whychooseus'> <img style={maxheight} className='float-right mr-5' alt='Why Choose Us' src='images/why.png' /></Link>

                            </div>
                            {/* <div className="col-lg-6 my-auto showcase-text text-left">
                                <h2>Why Choose Us</h2>
                                <ul className="lead mb-0">
                                    <li>Ethical Business</li>
                                    <li>Specializes in business advice and financial consultation</li>
                                    <li>Highly experienced and qualified professionals as partners</li>
                                    <li>High quality service to meet the expectations</li>
                                    <li>Cultivate Long-Term Relationships With Our Clients</li>
                                </ul>
                            </div> */}
                            {/* </div>
                        <div className="row no-gutters"> */}
                            {/* <div className="col-lg-6 order-lg-2 text-white showcase-img" style={howwework}></div> */}
                            <div className="col-lg-6 order-lg-2 text-white mb-2  mt-4 showcase-img">


                                <Link to='/howwework'>   <img style={maxheight} className='float-left ml-5' alt='How we Work' src='images/howwework.png' /></Link>

                            </div>
                            {/* <div className="col-lg-6 order-lg-1 my-auto showcase-text text-left">
                                <h2>How we Work</h2>
                                <ul className="lead mb-0">
                                    <li style={listnone}>Step 1:  Information Gathering </li>
                                    <li style={listnone}>Step 2: Risk Evaluation</li>
                                    <li style={listnone}>Step 3: Define scope of work</li>
                                    <li style={listnone}>Step 4: Acceptance & Contracting</li>
                                    <li style={listnone}>Step 5: Identifying Solutions and Execution… <Link to='/howwework'> read more...</Link></li>

                                </ul>
                            </div> */}
                        </div>
                    </div>
                </section>

                {/* <section className="features-icons bg-light text-center">
                    <div className="container">
                        <div className="row">
                            <div>
                            </div>
                            
                            <div className="col-lg-4">
                                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                                    <Link to='/legal' style={nounderline}>
                                        <div className=" features-icons-icon d-flex" style={legal}>
                                        </div>
                                        <h3 class="text-dark">Legal</h3>
                                        <p className="lead mb-0 text-dark">We are providing all type of legal services relating to business concerns</p>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3" >
                                    <Link to='/audit' style={nounderline}>

                                        <div className=" features-icons-icon d-flex" style={audit}>
                                        </div>
                                        <h3 class="text-dark">Audit</h3>
                                        <p className="lead mb-0 text-dark"> Annual Auditing, Internal Auditing,
                                    Compliance Audit, Due Diligence Audit, Quality assurance audit, Tax Audit</p>
                                    </Link>
                                </div>
                            </div>


                            

                            <div className="col-lg-4">
                                <div className="features-icons-item mx-auto mb-0 mb-lg-3" >
                                    <Link to='/payrollandaccountng' style={nounderline}>
                                        <div className=" features-icons-icon d-flex" style={aap}>
                                        </div>
                                        <h3 class="text-dark">Payroll and Accounting</h3>
                                        <p className="lead mb-0 text-dark">We are providing payroll management services. </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div >

        );
    }
}
export default Body;
