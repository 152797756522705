import React, { Component } from 'react';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"
import NavBar from "../NavBar"
import Footer from '../footer';



class Helpdesk extends Component {



    render() {
        // const sliderdiv = {
        //                 height: '50px',
        //                 maxHeight: '200px',
        //                 backgroundSize: 'cover',
        //                 backgroundPosition: 'center',
        //                 backgroundRepeat: 'no-repeat',
        //             };

        return (

            <div className="bg-light">
                {/* navbar div start */}
                <div>
                    <NavBar />
                </div>
                {/* navbar div end */}
                {/* body              */}

                <div className='container'>
                    <h1>Help Desk</h1>
                    <h3>We will be glad to help you out... </h3>
                    <form>
                        <div className="form-group col-md-8">
                            <label  >Full Name</label>
                            <input type="text" className="form-control" required placeholder="Enter Full Name" />
                        </div>
                        {/* <div className="form-group col-md-8">
                            <label f >Address</label>
                            <input type="address" className="form-control"  required id="exampleInputaddress " placeholder="Address" />
                        </div> */}
                        <div className="form-group col-md-8">
                            <label   >Contact Number</label>
                            <input type="contactnumber" className="form-control" required id="exampleInputcontactnumber " placeholder="Contact Number" />
                        </div>
                        <div className="form-group col-md-8">
                            <label  >Email</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" required aria-describedby="emailHelp" placeholder="Enter email" />
                            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                        </div>
                        <div className="form-group col-md-8">
                            <label >What type of help?</label>
                            <select required class="custom-select">
                                <option selected>Type of Help</option>
                                <option value="1">Business Advisory </option>
                                <option value="2">Financial Consulting</option>
                                <option value="3">Accounting and Payroll</option>
                                <option value="4">Tax Consultant</option>
                                <option value="5">Risk Advisory</option>
                                <option value="6">Others</option>
                            </select>
                        </div>


                        <div className="form-group col-md-8">
                            <label  >Message</label>
                            <textarea required className="form-control" rows="4" cols="50">
                            </textarea>
                        </div>


                        <div className="form-group col-md-8">
                            <button type="submit" className="btn btn-primary ">Submit</button>
                        </div>
                    </form>
                </div>


                {/* footer div start*/}
                <div>
                    <Footer />
                </div>
                {/* footer div end*/}

            </div >
        );
    }
}
export default Helpdesk;

