import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css'
import './css/header.css'
import './css/body.css'
import './css/footer.css'
import './css/navbar.css'
import './css/navbar.css'
import './css/others.css'
import './css/sidebar.css'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Homepage from './component/navbar/Homepage';
import Ourservices from './component/navbar/OurServices';
import Aboutus from './component/navbar/AboutUs';
import Contactus from './component/navbar/ContactUs';
import Others from './component/ourservices/Other';
import RiskAdvisory from './component/ourservices/riskadvisory';
import TaxConsultant from './component/ourservices/taxconsultant';
import FinancialConsultancy from './component/ourservices/financialconsultancy';
import PayrollandAccounting from './component/ourservices/payrollandaccounting';
import Ourteam from './component/aboutus/ourteam';
import Missionvision from './component/aboutus/missionvision';
import Ourstory from './component/aboutus/ourstory';
import Howwework from './component/aboutus/howwework';
import Helpdesk from './component/card/helpdesk';
import Guide from './component/card/guide';
import Careers from './component/card/careers';
import ImpLinks from './component/others/links';
import Whychooseus from './component/aboutus/whychooseus';
import BusinessAdvisory from './component/ourservices/businessadvisory';

function App() {
    return (


        <Router>
            <div className="App">
                {/* nav */}

                < Route path="/" exact component={Homepage} />
                < Route path="/ourservices" exact component={Ourservices} />
                < Route path="/aboutus" exact component={Aboutus} />
                < Route path="/contactus" exact component={Contactus} />
                {/* nav */}

                {/* ourservices */}
                < Route path="/others" exact component={Others} />
                < Route path="/riskadvisory" exact component={RiskAdvisory} />
                < Route path="/taxconsultant" exact component={TaxConsultant} />
                < Route path="/financialconsultancy" exact component={FinancialConsultancy} />
                < Route path="/payrollandaccountng" exact component={PayrollandAccounting} />
                < Route path="/businessadvisory" exact component={BusinessAdvisory} />
                {/* ourservices */}


                {/* aboutus */}
                < Route path="/ourteam" exact component={Ourteam} />
                < Route path="/missionvision" exact component={Missionvision} />
                < Route path="/ourstory" exact component={Ourstory} />
                {/* aboutus */}




                {/* Card */}
                < Route path="/guide" exact component={Guide} />
                < Route path="/helpdesk" exact component={Helpdesk} />
                < Route path="/careers" exact component={Careers} />
                {/* Card */}

                {/* Contact us */}
                {/* < Route path="/contactus" exact component={Contactus} /> */}
                {/* Contact us */}


                 {/* others */}
                < Route path="/implink" exact component={ImpLinks} />
                < Route path="/howwework" exact component={Howwework} />
                < Route path="/whychooseus" exact component={Whychooseus} />




            </div>
        </Router >
    );


}

export default App;