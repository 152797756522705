import React, { Component } from 'react';
import NavBar from "../NavBar"
import Footer from '../footer';







class Howwework extends Component {



    render() {

        return (

            <div>
                <div>
                    <NavBar />
                </div>


                <div>

                    {/* <!-- Icons Grid --> */}
                    <section className="features-icons bg-light text-center">
                        <div className="container ">
                            <h2 className="text-left">How we work… </h2>


                            {/* <p className="font-weight-light text-justify mb-0"> */}


                            <h5 className='text-left'><u>Step 1:  Information Gathering </u></h5>
                            <p className="font-weight-light text-justify mb-2 ml-2">  In the initial stage, we meet with client to gain/update our understanding of developments in the client’s business and industry, the business objectives and related risks and controls, and their needs and expectations.</p>

                            <h5 className='text-left'><u>Step 2: Risk Evaluation</u></h5>
                            <p className="font-weight-light text-justify mb-2 ml-2"> In this step, we discuss the problems and challenges faced by the client. Here, we conduct extensive study, research and SWOT analysis to understand underlying issues and gain a clearer picture of the broader scenario. Professionals at BSP, analyze the business and context to create solutions and assist clients in meeting their organizational objectives.</p>

                            <h5 className='text-left'><u>Step 3: Define scope of work…</u></h5>
                            <p className="font-weight-light text-justify mb-2 ml-2">  After intensive study & research on the problems, threats, challenges and opportunities, the next step is to develop a "Scope of Work" which includes the background, work plan, and deliverables along with a timeline for all deliverables.</p>

                            <h5 className='text-left'><u>Step 4: Acceptance & Contracting…</u></h5>
                            <p className="font-weight-light text-justify mb-2 ml-2"> After the finalization and acceptance of the "Scope of Work" by both parties, we move towards contracting.</p>

                            <h5 className='text-left'><u>Step 5: Identifying Solutions and Execution…</u></h5>
                            <p className="font-weight-light text-justify mb-2 ml-2">  We leverage our expertise along with experienced team and delivering customized   & proficient solutions for obtaining desired results.</p>



                            {/* </p> */}


                            <div className="row">


                            </div>
                        </div>
                    </section>
                </div >



                {/* footer div start*/}
                <div>
                    <Footer />
                </div>
                {/* footer div end*/}
            </div >
        );
    }
}
export default Howwework;

