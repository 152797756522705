import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { Button, Navbar, Nav, Form, FormControl } from "react-bootstrap"


class Body extends Component {

    render() {
        const mapsapi = {
            width: '100%',
            height: '500px',
            frameborder: '0',
            border: '0',
            allowfullscreen: ''
        }
        return (
            <div className='bg-light'>
                {/* <div className="container">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d883.0989601567342!2d85.3189148291779!3d27.705061998924563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19a9e351977d%3A0x919e25d60c8f27c7!2sLcp%20It%20Solution!5e0!3m2!1sen!2snp!4v1577251999356!5m2!1sen!2snp" style={mapsapi}></iframe>
                </div> */}
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <Link to='/'><a href="/" className="breadcrumb-item">Home/ </a></Link>
                                    <li className="breadcrumb-item active" aria-current="page">Contact</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                            <div className="card-header bg-primary text-white"><i className="fa fa-envelope"></i> Location.
                            </div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d883.0989601567342!2d85.3189148291779!3d27.705061998924563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19a9e351977d%3A0x919e25d60c8f27c7!2sLcp%20It%20Solution!5e0!3m2!1sen!2snp!4v1577251999356!5m2!1sen!2snp" title="googlemaps" style={mapsapi}></iframe>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className="card bg-light mb-3">
                                <div className="card-header bg-primary text-white"><i className="fa fa-envelope"></i> Contact us.
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="form-group">
                                            <label for="name">Name</label>
                                            <input type="text" className="form-control" id="name" aria-describedby="emailHelp" placeholder="Enter name" required />
                                        </div>
                                        <div className="form-group">
                                            <label for="email">Email address</label>
                                            <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email" required />
                                            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                                        </div>
                                        <div className="form-group">
                                            <label for="message">Message</label>
                                            <textarea className="form-control" id="message" rows="6" required></textarea>
                                        </div>
                                        <div className="mx-auto">
                                            <button type="submit" className="btn btn-primary text-right">Submit</button></div>
                                    </form>
                                </div>






                            </div>
                        </div>
                    </div>
                </div>
            </div >

        );
    }
}
export default Body;
